<template>
  <div style="padding-bottom: 300px">
    <template v-if="view_permission">
      <b-card v-if="is_admin">
        <b-row style="margin-bottom: -1rem;">
          <b-col class="col-6 col-md-4 col-lg-3">
            <b-form-group :label="$t('common.company')">
              <v-select
                label="text"
                :options="company_options"
                :reduce="text => text.value"
                v-model="company_id"
                :clearable="false"
                :searchable="false"
                @option:selected="changeCompany"
              >
                <template #no-options>
                  {{ $t('common.no_options') }}
                </template>
              </v-select>
            </b-form-group>

          </b-col>
          <b-col class="col-6 col-md-4 col-lg-3">
            <b-form-group :label="$t('common.facility')">
              <v-select
                label="text"
                :options="facility_options"
                :reduce="text => text.value"
                v-model="facility_id"
                :clearable="false"
                :searchable="false"
                @option:selected="changeFacility"
              >
                <template #no-options>
                  {{ $t('common.no_options') }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <edit-field-header
          :title="title"
          :show-back-btn="false"
          :sub-title="$t('device_raw_data.warning_message')"
          sub-title-class="text-danger"
        />
        <edit-field
          :fields="fields"
          :rowData="rowData"
          post-api="/bemonitor/DownloadRawData"
          finish-router="deviceRawData"
          :submitBtnText="$t('device_raw_data.export_data')"
          :submitAction="submitAction"
          @beforeSubmit="beforeSubmit"
        >
          <template #bed="scope">
            <slot-bed
              ref="slot_bed"
              :row-data="rowData"
              :facility-id="rowData.facility_id"
              @addBed="addBed"
              @changeBedFacilityId="changeBedFacilityId"
              :slotConfig="fields[0].slot_config"
            />
          </template>
        </edit-field>
      </b-card>

      <modal-bed
        ref="model_bed"
        @changeBed="changeBed"
        :title="$t('device_raw_data.modal_bed_title')"
      />
    </template>
    <b-card v-else>{{ $t('permission.no_view') }}</b-card>

  </div>
</template>

<script>
import common from '@/common'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ButtonPermission from '@/components/ButtonPermission'
import SlotBed from '@/views/group/SlotBed'
import ModalBed from '@/views/group/ModalBed'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import fun from '@/views/group/fun.js'

export default {
  name: 'bedexitrawdata',
  components: {
    ButtonPermission,
    flatPickr,
    vSelect,
    SlotBed,
    ModalBed,
    EditField,
    EditFieldHeader
  },
  data() {
    return {
      // title: common.getI18n('device_raw_data.title'),
      title: common.getMenuName('bedexitrawdata'),
      view_permission: common.checkPermission('Mon_RawData_Download'),
      is_admin: common.isAdmin(),
      company_options: [],
      facility_options: [],
      show_report_type: 'all',
      time_option: [],
      company_id: common.getCompanyId(),
      facility_id: common.getFacilityId(),

      // 表單的默認信息
      rowData: {
        facilityID: common.getFacilityId(),
        beds: []
      },

      fields: [
        {
          label: 'common.bed',
          name: 'bedIDs',
          type: 'slot',
          slot: 'bed',
          rule: 'required',
          validate_in_slot: true,
          slot_config: {
            name: 'bedID',
            label: 'common.bed',
            rule: 'required',
          }
        },
        {
          label: 'device_report.start_date',
          name: 'startDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(-6, 'days')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        },
        {
          label: 'device_report.end_date',
          name: 'endDate',
          type: 'date',
          rule: 'required',
          config: {
            wrap: true,
            minDate: null,
            maxDate: this.$moment()
              .add(1, 'hours')
              .format('YYYY-MM-DD'),
          },
          default: this.$moment()
            .add(1, 'hours')
            .format('YYYY-MM-DD'),
          on_change: this.handleDateChange,
        }
      ]
    }
  },

  mounted() {
    // initialize time_option
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? '0' + i : i
      const time = hour + ':00'
      this.time_option.push({
        text: time,
        value: time
      })
    }

    // set default value from fields to forms
    this.fields.forEach((field) => {
      if (field.default !== undefined && field.name) {
        this.$set(this.rowData, field.name, field.default)
      }
    })
  },

  activated() {
    if (common.isAdmin()) {
      common.getCompanyOptions()
        .then(res => {
          this.company_options = res
        })
      common.getFacilityOptions(this.company_id)
        .then(res => {
          this.facility_options = res
        })
    }
  },

  methods: {
    handleDateChange({dateType, dateStr}) {
      const fields = this.fields
      const startDateIndex = fields.findIndex(field => field.name === 'startDate')
      const endDateIndex = fields.findIndex(field => field.name === 'endDate')
      common.syncFieldsOfDateRange({
        dayRange: 7,
        changedDateType: dateType,
        changedDateStr: dateStr,
        vmDateWrapperObj: this.rowData,
        vmStartDateConfig: startDateIndex >= 0 ? fields[startDateIndex].config : undefined,
        vmEndDateConfig: endDateIndex >= 0 ? fields[endDateIndex].config : undefined,
      })
    },

    async changeCompany(data) {
      this.facility_id = null
      const FacilityOptions = await common.getFacilityOptions(data.value)
      const DefaultFacilityId = typeof(FacilityOptions[0]?.value) === 'number' ? FacilityOptions[0].value : null
      this.facility_options = FacilityOptions
      this.facility_id = DefaultFacilityId

      // common.syncSearchFields({
      //   companyID: data.value,
      //   facilityID: DefaultFacilityId,
      //   floorID: null,
      //   roomID: null,
      // })
    },

    changeFacility: function (data) {
      // common.syncSearchFields({
      //   companyID: this.company_id,
      //   facilityID: data.value,
      //   floorID: null,
      //   roomID: null,
      // })
    },

    clear: function (type, name) {
      this.search[type][name] = null
    },

    addBed: function () {
      fun.addBed(this)
      this.$refs.model_bed.checkSelectAll()
    },
    changeBed: function (type, option) {
      fun.changeBed(this, type, option)
    },

    beforeSubmit: function (callback) {
      const res_1 = this.$refs.slot_bed ? this.$refs.slot_bed.buildData() : true
      callback(res_1)
    },

    changeBedFacilityId: function (facilityID) {
      this.$refs.model_bed.changeFacilityId(facilityID)
    },

    submitAction: function (rowData) {

      // check date
      const startMoment = this.$moment(`${this.rowData['startDate']}`)
      const endMoment = this.$moment(`${this.rowData['endDate']}`)
      if (endMoment.diff(startMoment) < 0) {
        common.showError('ReportConditionDateError')
        return false
      }

      common.apiDownloadData({
        url: '/bemonitor/DownloadRawData',
        method: 'post',
        timeout: 2 * 60 * 60 * 1000,
        data: {
          startDate: rowData.startDate,
          endDate: rowData.endDate,
          facilityID: rowData.facilityID,
          bedIDs: rowData.bedIDs,
        },
      })
    },
  },
  watch: {
    facility_id(val, oldVal) {
      this.rowData.facilityID = val
      this.changeBedFacilityId(val)
      this.$refs.slot_bed.clearBed()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.reportWrapper > hr {
  margin: 2rem 0;
}

.reportWrapper:last-of-type > hr {
  display: none;
}
</style>
